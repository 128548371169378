/* @import url('https://fonts.googleapis.com/css2?family=Inter&family=Open+Sans:wght@300;400;500;600;700&display=swap'); */
body{
    font-family: 'Open Sans', sans-serif !important;
    font-size: 16px;
    line-height: 24px;
    color:#000000;
}
.login-register-section .c-container{
    /* max-width: 1270px; */
    max-width: 100%;
    padding: 0 56px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.grey-bg{
    background-color: #f5f5f5;
}
a{
    transition: all ease-in .3s;
}

/*header part start*/
header{
    background-color: #fff;
}
header nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

header nav ul li a{
    /* margin-left: 90px; */
    cursor: pointer;
}

header nav ul li:first-child {
    margin-left: 0;
}

header nav ul li a {
    color: #000;
    text-decoration: none;
    font-family: inherit;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

header nav ul li a:hover {
    opacity: .7;
    color: #000;
}
header nav ul li.active-page a {
    font-weight: 700;
}


header .col-sm-10.col-md-10.col-lg-10.my-auto {position: relative;}

header .col-sm-10.col-md-10.col-lg-10.my-auto button.navbar-toggler {
    position: absolute;
    right: 13px;
    top: 29px;
    z-index: -9;
    /* background: #000; */
    width: 30px;
    padding: 0;
    border-radius: 0px;
    display: none;
}

header .col-sm-10.col-md-10.col-lg-10.my-auto button.navbar-toggler span {
    position: relative;
    display: block;
    width: 100%;
}

header .col-sm-10.col-md-10.col-lg-10.my-auto button.navbar-toggler span:before,
header .col-sm-10.col-md-10.col-lg-10.my-auto button.navbar-toggler span:after {
    content: "";
    width: 100%;
    height: 3px;
    position: absolute;
    background: #000;
    left: 0;
    right: 0;
}

header .col-sm-10.col-md-10.col-lg-10.my-auto button.navbar-toggler span:before {
    top: 9px;
}

header .col-sm-10.col-md-10.col-lg-10.my-auto button.navbar-toggler span:after {
    top: 18px;
}

/*header part end*/

/*login register start*/

.login-block-box {
    padding: 30px 40px 53px;
    border-radius: 12px;
    background: rgb(255,255,255,95%);
    max-width: 522px;
    margin-left: auto;
}

.login-block-box ul.nav {
    width: 100%;
    border: none;
}

.login-block-box ul.nav li {
    flex: auto;
}

.login-block-box ul.nav li .nav-link {
    width: 100%;
    font-size: 20px;
    border: none;
    font-weight: 400;
    color: #000;
    padding: 5px 10px 15px;
    margin: 0;
    position: relative;
    background-color: transparent;
    text-align: center;
}

.login-block-box ul.nav li .nav-link:hover{
    opacity: .7;
}

.login-block-box ul.nav li .nav-link.active:before {
    content: "";
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background: #000;
    left: 0;
    right: 0;
}

.login-block-box .tab-pane {
    padding: 32px 0 0;
}

.login-block-box .tab-pane .form-floating .form-control {
    height: 68px;
    padding-top: 30px;
    border-color: rgba(33, 43, 54, 0.1);
    border-radius: 8px;
    outline: none;
    box-shadow: none;
    padding-left: 15px;
}

.login-block-box .tab-pane .form-floating label {
    padding-top: 20px;
    color:#000;
    padding-left: 15px;
}

.form-floating>.form-control:focus~label, 
.form-floating>.form-control:not(:placeholder-shown)~label, 
.form-floating>.form-select~label {
    opacity: 1;
    color: #808080;
    font-size: 12px;
}

.login-block-box .form-floating .password-show {
    position: absolute;
    right: 20px;
    top: 32px;
}
.login-block-box .form-floating .password-show span.input-group-text {
    background-color: transparent;
    border: none;
    color: #000; 
    padding: 0;
    font-size: 18px;
}
.btn.black-btn {
    width: 100%;
    background: #000;
    color: #fff;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 600;
    height: 56px;
    border: 1px solid #000;
}

.btn.black-btn:hover {
    background-color: transparent;
    color: #000;
}



.form-group a.form-link {
    font-size: 14px;
    color: #000;
    text-decoration: none;
    font-weight: 400;
}

.form-group a.form-link:hover {
    text-decoration: underline;
}

.formtitle-box h3 {
    font-size: 16px;
    font-weight: 400;
    color: rgb(0 0 0 / 70%);
    margin: 0;
    padding: 10px 0;
    position: relative;
}

.formtitle-box h3:before {
    content: "";
    position: absolute;
    top: 50%;
    height: 1px;
    width: 100%;
    background: #000;
    left: 0;
    right: 0;
}

.formtitle-box h3 span {
    padding: 0 25px;
    background: rgb(251 250 249);
    display: inline-block;
    position: relative;
}

span.full-btn {
    width: 100%;
    display: block;
    margin: 0px 0 16px;
}
span.full-btn:last-child{
    margin-bottom: 0;
}
.btn.blank-btn {
    width: 100%;
    color: #000;
    border-radius: 30px;
    font-size: 18px;
    font-weight: 600;
    height: 56px;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
}

span.full-btn a.btn.blank-btn img {
    margin-right: 20px;
}

.btn.blank-btn:hover {
    background: #000;
    color: #fff;
}

span.full-btn a.btn.blank-btn:hover img {
    filter: contrast(1) invert(1); 
}
.password-show .hide_eye {
    display: none;
}
.form-radiobutton { 
    padding-top: 0; 
    margin-bottom: 40px; 
}

.form-radiobutton .form-check {
    position: relative;
    padding: 0;
    margin-bottom: 16px;
}

.form-radiobutton .form-check input[type="radio"],
.form-radiobutton .form-check input[type="checkbox"]{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    /* visibility: hidden; */
    margin: 0;
    border-radius: 0px;
}

.form-radiobutton .form-check label.form-check-label:before {
    content: "";
    width: 19px;
    height: 19px;
    border: 1px solid rgb(33 43 54 / 20%);
    position: absolute;
    left: 0;
    border-radius: 50%;
    top: 3px;
}

.form-radiobutton .form-check label.form-check-label {
    position: relative;
    width: 100%;
    padding-left: 30px;
    cursor: pointer;
}

.form-radiobutton .form-check label.form-check-label:after {
    content: "\f00c";
    width: 19px;
    height: 19px;
    border: 1px solid rgb(33 43 54 / 20%);
    position: absolute;
    left: 0;
    border-radius: 50%;
    top: 3px;
    background: #000;
    color: #fff;
    font-family: 'Font Awesome 5 Free';
    font-weight: 600;
    font-size: 12px;
    text-align: center;
    line-height: 18px;
    transform: scale(0);
    transition: all ease-in .2s;
}

.form-radiobutton .form-check  input[type="radio"]:checked + label.form-check-label:after,
.form-radiobutton .form-check  input[type="checkbox"]:checked + label.form-check-label:after{
    transform: scale(1);
}
section.login-register-section {
/*    height: calc(100vh - 245px);*/
    height: auto;
    height: 762px;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}
.titlewith-subtit h1 {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 22px;
}
.subscript-listing ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.subscript-listing ul li i {
    margin-right: 5px;
}

.subscript-listing ul li {
    line-height: 20px;
    font-weight: 700;
    margin-bottom: 12px;
}
.subscirpt-block {
    background: #fff;
    border-radius: 16px;
    padding: 0;
    position: relative;
    margin-bottom: 10px;
}

.subscirpt-block .radioblock-div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.subscirpt-block label {
    width: 100%;
    padding: 10px 12px 10px 56px;
    cursor: pointer;
    border: 2px solid #fff;
    border-radius: 16px;
    transition: all ease-in .3s;
    -webkit-transition: all ease-in .3s;
    -moz-transition: all ease-in .3s;
    -ms-transition: all ease-in .3s;
}

.subscirpt-block .radioblock-div .right-subsi-blk {
    margin-right: 0;
    margin-left: auto;
    max-width: 100px;
    width: 100%;
}

.subscirpt-block input[type="radio"] {
    position: absolute;
    visibility: hidden;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
    right: 0;
}

.subscirpt-block .radioblock-div p {
    margin: 0;
    font-size: 14px;
    line-height: 18px;
    width: 100%;
}

.left-subsi-blk h5 {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    margin: 0 0 3px 0;
    line-height: 30px;
}

.subscirpt-block .radioblock-div .left-subsi-blk {
    /* justify-content: center; */
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    /* align-items: center; */
    margin-top: auto;
    margin-bottom: auto;
    width: calc(100% - 100px);
    padding-right: 10px;
}

.subscirpt-block .radioblock-div .left-subsi-blk h5 {
    width: 100%;
    /* height: auto; */
}

.subscirpt-block .radioblock-div .right-subsi-blk h4 {
    font-size: 24px;
    margin: 0;
    font-weight: 600;
}

.subscirpt-block .radioblock-div .right-subsi-blk span {
    font-size: 12px;
    text-decoration: line-through;
    display: block;
    line-height: 12px;
}
.subscirpt-block .radioblock-div .right-subsi-blk h4 sub {
    font-size: 16px;
    line-height: 10px;
    bottom: -1px;
}
.subscirpt-block label:before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 2px solid #629C88;
    top: 50%;
    left: 17px;
    transform: translateY(-50%);
    border-radius: 50%;
    transition: all ease-in .3s;
    -webkit-transition: all ease-in .3s;
    -moz-transition: all ease-in .3s;
    -ms-transition: all ease-in .3s;
}

.subscirpt-block label:after {
    content: "";
    position: absolute;
    position: absolute;
    width: 12px;
    height: 12px;
    border: 0px solid #629C88;
    top: 50%;
    left: 21px;
    transform: translateY(-50%) scale(0);
    border-radius: 50%;
    background: #629C88;    
}

.subscirpt-block input[type="radio"]:checked + label:after {
    transform: translateY(-50%) scale(1);
}
.subscirpt-block input[type="radio"]:checked + label {
    border: 2px solid #629C88;
    border-radius: 16px;
    background: #93E7C9;
}
.payment-textblk {
    border-top: 1px solid rgb(0 0 0 / 10%);
    border-bottom: 1px solid rgb(0 0 0 / 10%);
}

.payment-textblk label {
    font-size: 20px;
    margin: 0;
    line-height: 30px;
    width: 100%;
    color: #000;
}

.payment-textblk h3 {
    font-size: 24px;
    text-align: right;
    margin: 0;
    color: #000;
}

.person-activeysection {
    padding: 60px 0 30px;
}

.top-title h1 {
    font-size: 38px;
    line-height: 57px;
    font-weight: 400;
}

.top-title {
    margin-bottom: 25px;
}

.person-activity-blk {
    height: 345px;
    background-size: cover;
    background-position: center;
    border-radius: 12px 12px 0 0;
}

.person-actdetail {
    background: #fff;
    border-radius: 0 0 12px 12px;
    position: relative;
}

.person-actdetail a {
    color: #000;
    text-decoration: none;
}

.person-actdetail a h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    margin: 0 0 7px 0;
}

.person-actdetail a p {
    margin: 0;
    font-size: 18px;
    line-height: 27px;
    font-weight: 400;
}

.person-actdetail a {
    position: relative;
    padding: 24px 65px 24px 24px;
    display: block;
}

.person-actdetail a span.link-arrows {
    position: absolute;
    right: 26px;
    top: 37px;
}

.person-actdetail a:hover * {
    opacity: .7;
}
.traing-img {
    height: 320px;
    background-size: cover;
    background-position: center;
    width: 100%;
    background-repeat: no-repeat;
}

.traing-repeat-block {
    padding: 0 12px;
}

.trainit-detail a {
    color: #000;
    text-decoration: none;
    padding: 20px 75px 20px 16px;
    display: block;
    background: #fff;
    position: relative;
}

.trainit-detail a:hover *{
    opacity: .7;
}

.trainit-detail a h4 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 700;
}

.trainit-detail a p {
    font-size: 18px;
    line-height: 27px;
    margin: 0;
}

.trainit-detail a span.play-icon {
    position: absolute;
    right: 30px;
    top: 30px;
}
.train-inner {
    border-radius: 14px;
    overflow: hidden;
}

.training-slider i.slick-arrow,
.recept-slider i.slick-arrow{
    position: absolute;
    top: 44%;
    transform: translateY(-44%);
    font-size: 0;
    border: none;
    background-color: rgb(255 255 255 / 50%);
    padding: 0;
    z-index: 99;
    line-height: normal;
    width: 40px;
    height: 40px;
    box-shadow: none;
    border-radius: 50%;
    transition: all ease-in .3s;
}

.training-slider i.slick-prev,
.recept-slider i.slick-prev{
    left: -40px;
}

.training-slider i.slick-next,
.recept-slider i.slick-next{
    right: -40px;
}

.training-slider i.slick-arrow:before,
.recept-slider i.slick-arrow:before{
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: 0;
    background-size: 12px;
    background-position: center;
    background-repeat: no-repeat;
}

.training-slider i.slick-arrow.slick-prev:before,
.recept-slider i.slick-arrow.slick-prev:before{
    background-image: url(assets/images/right-arrow.svg);
    transform: rotate(180deg);
    cursor: pointer;
}

.training-slider i.slick-arrow.slick-next:before,
.recept-slider i.slick-arrow.slick-next:before{
    background-image: url(assets/images/right-arrow.svg);   
    cursor: pointer; 
}
/* .training-slider,
.recept-slider {
    overflow: hidden;
} */

.training-slider:hover .slick-next,
.recept-slider:hover .slick-next{
    right: 40px;
}
.training-slider:hover .slick-prev,
.recept-slider:hover .slick-prev{
    left: 40px;
}
.common-title h3 {
    font-size: 28px;
    line-height: 42px;
    font-weight: 600;
    margin: 0;
}

.common-title {
    margin-bottom: 23px;
}

.recept-inner {
    height: 300px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.recept-repeat-block {
    padding: 0 15px;
}

.recept-infor {
    background: #fff;
}

.recept-infor h4 {
    margin: 0 0 10px 0;
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
}

.recept-infor {
    padding: 16px;
}

.recept-infor ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
}

.recept-infor ul li {
    font-size: 18px;
    font-weight: 400;
    color: #808080;
    margin-right: 35px;
    position: relative;
}
.recept-slider {
    margin: 0 -15px;
}
.recept-inner-block {
    border-radius: 12px;
    overflow: hidden;
} 
.podcast-leftimg img {
    border-radius: 16px;
    width: 100%;
}

.podcast-block .leftpod-img {
    max-width: 175px;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
}

.podcast-block {
    display: flex;
    flex-wrap: wrap;
    background: #fff;
    border-radius: 12px;
    padding: 13px 16px;
    margin-bottom: 20px;
}

.podcast-block .rigpod-cont {
    padding: 5px 50px 10px 25px;
    width: calc(100% - 175px);
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
}

.rigpod-cont h3 {
    font-size: 22px;
    line-height: 33px;
}

.podcast-block .rigpod-cont a.link-podcast {
    position: absolute;
    right: 0;
    top: 45px;
}

.rigpod-cont p {
    font-size: 18px;
    line-height: 24px;
    color: #808080;
    margin: 0;
}
.common-title.rightadd-link {
    display: flex;
    flex-wrap: wrap;
}

.common-title.rightadd-link a.tot-riglink {
    margin-right: 0;
    margin-left: auto;
    margin-top: auto;
    margin-bottom: auto;
    color: #000;
}

.common-title.rightadd-link a.tot-riglink:hover {
    /* text-decoration: none; */
    opacity: .7;
} 

body {
    overflow-x: hidden;
}
.podcast-block .leftpod-img img {
    width: 100%;
}

.recepit-page-block {
    margin: 0 -12px;
    display: flex;
    flex-wrap: wrap;
}

.recepit-page-block .recept-repeat-block {
    width: 33%;
    padding: 12px;
}

.recepit-page-block .recept-repeat-block .recept-inner-block {
    height: 100%;
    background-color: #fff;
    border-radius: 6px;
}
.recept-infor ul li:before {
    content: "";
    position: absolute;
    width: 3px;
    height: 3px;
    background: #666;
    border-radius: 50%;
    left: 0;
    top: 11px;
    left: -19px;
}

.recept-infor ul li:first-child:before {
    display: none;
}
.recept-inner-block a {
    text-decoration: none;
    color: #000;
}
.recept-inner-block a:hover .recept-inner {
    filter: grayscale(1);
}
.recept-inner-block a .recept-inner {
    transition: all ease-in .2s;
}
.searc-block {
    max-width: 463px;
    margin: 0 auto;
}

.searc-block .form-group .form-control {
    height: 56px;
    border-radius: 8px;
    border-color: rgb(0 0 0 / 25%);
    font-size: 14px;
    padding: 10px 20px 10px 48px;
    font-family: 'Open Sans';
}

.searc-block .form-group {
    position: relative;
}

.searc-block .form-group button.search-btn {
    position: absolute;
    left: 0;
    top: 0;
    border: none;
    background-color: transparent;
    height: 56px;
    width: 50px;
    font-size: 18px;
}
.searc-block .form-group .form-control:focus {
    outline: none;
    box-shadow: none;
}
.tabbing-infobox .nav.nav-tabs {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    border:none;
}

.tabbing-infobox .nav.nav-tabs .nav-link {
    color: #000;
    text-decoration: none;
    font-size: 20px;
    font-weight: 300;
    position: relative;
    margin: 0 20px;
    padding: 0 0 5px 0;
    border: none;
    background: transparent;
}
 
.tabbing-infobox .nav.nav-tabs .nav-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 1px;
    background: #000;
    transition: all ease-in .2s;
}

.tabbing-infobox .nav.nav-tabs .nav-link:hover:after {
    width: 100%;
}

.tabbing-infobox {
    padding: 20px 0;
}
.tabbing-infobox nav {
    margin-bottom: 24px;
}

.tabbing-infobox .nav.nav-tabs .nav-link.active {
    font-weight: 600;
}

.tabbing-infobox .nav.nav-tabs .nav-link.active:after {
    width: 100%;
}
.recipes-detcontain {
    max-width: 855px;
    margin: 0 auto;
}

.detail-img img {
    width: 100%;
    border-radius: 16px;
}

.top-breakcrumb-box {
    margin-bottom: 50px;
}

a.back-link {
    font-size: 16px;
    line-height: 22px;
    color: #000;
    text-decoration: none;
}

a.back-link img {
    transform: rotate(180deg);
    margin-right: 15px;
    margin-top: -2px;
}

a.back-link:hover {
    opacity: .7;
}

.detail-content {
    padding-top: 40px;
}

.title-box h1 {
    font-size: 32px;
    line-height: 43px;
    font-weight: 600;
}

.leftcontent-blk h3,.ritcont-list h3 {
    font-size: 20px;
    line-height: 27px;
    font-weight: 600;
    margin-bottom: 25px;
}

.ritcont-list ul li {
    font-size: 14px;
    color: #000;
    line-height: 21px;
    margin-bottom: 12px;
}

.leftcontent-blk ol li {
    font-size: 16px;
    line-height: 25px;
    margin-bottom: 20px;
    color: #000;
}

.leftcontent-blk ol {
    padding-left: 15px;
}

.leftcontent-blk ol li::marker {
    margin-right: 7px;
    /* position: absolute; */
} 

/*login register end*/



/*footer part start*/

.footer-logos span {
    display: inline-block;   
    margin-right: 5px;
}

/* .footer-logos span img {
    width: 100%;
    height: auto;
} */
.footer-logos{
    display: flex;
    gap: 10px;
    margin-top: 32px !important;
}
/* .footer-logos span a {
    border: 1px solid #000;
    display: flex;
    border-radius: 10px;
    overflow: hidden;
    padding: 7px;
    height: 46px;
    vertical-align: middle;
} */
footer{
    padding: 27px 0 21px 0;
}
.footer-block h5 {
    text-transform: uppercase;
    font-family: 'Open Sans';
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.05em;
    margin-bottom: 10px;
}

.social-links ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.social-links ul li {
    margin-bottom: 7px;
    line-height: normal;
}

footer .social-links ul li a {
    color: #000 !important;
    text-decoration: none;
    font-size: 14px;
    display: inline-block;
    font-weight: 400;
    cursor: pointer;
}

footer .pointer, footer .pointer * {
    cursor: pointer;
}

footer img {
    cursor: pointer;
}

footer .social-links ul li a img {
    margin-right: 5px;
    vertical-align: middle;
}
footer {
    background: #fff;
}
/*footer part end*/


/* angular component modification css */
/* .paginations ul li.current{
    background: #000;
}
.paginations .ngx-pagination .current{
    background: #000;
} */

.btn.disabled, .btn:disabled{
    background-color: #9E9E9E;
    border-color:#9E9E9E ;
}

.search-div {
    margin-top: 10px;
    margin-bottom: 15px !important;
}


/*responsive*/
@media(max-width:1340px){
    .container {
        max-width: 1100px;
    }
    .recepit-page-block .recept-repeat-block {
        width: 33.33%;
    }
}
@media(max-width:1240px){
    .container {
        max-width: 1000px;
    }
    .recept-slider {
        margin: 0;
    }
}
@media(max-width:1024px){
    .login-block-box {
        padding: 15px 20px;
    }
    header nav ul li {
        margin-left: 40px;
    }
    .person-actdetail a h3 {
        font-size: 20px;
        line-height: 28px;
    }
    .person-actdetail a p {
        font-size: 16px;
        line-height: 23px;
    }
    .podcast-block .leftpod-img {
        max-width: 125px;
    }
    .podcast-block .rigpod-cont{
        width: calc(100% - 125px);
    }
    .rigpod-cont h3 {
        font-size: 20px;
        line-height: 28px;
    }
    .rigpod-cont p {
        font-size: 16px;
        line-height: 22px;
    }
    .podcast-block .rigpod-cont a.link-podcast img {
        width: 30px;
    }
    .podcast-block .rigpod-cont a.link-podcast{
        top:30px;
    }
    .common-title h3{
        font-size: 24px;
        line-height: 34px;
    }
    .top-title h1{
        font-size: 32px;
        line-height: 42px;
    }
    .person-activeysection {
        padding: 30px 0;
    }
    .podcast-leftimg {
        margin-bottom: 15px;
    }
}
@media(max-width:991px){
    .login-register-section .col-md-5.offset-md-7 {
        margin-left: 0;
        width: 100%;
    }
    header .col-sm-10.col-md-10.col-lg-10.my-auto button.navbar-toggler{
        display: block;
        z-index: 99;
        border: none;
        box-shadow: none;
    }
    .navbar-collapse {
        display: none;
    }
    .navbar-collapse.collapse.show {
        display: block;
    }
    .navbar-collapse.collapse {
        position: absolute;
        top: 100%;
        width: 100%;
        left: 0;
        z-index: 9;
    }

    header .col-sm-10.col-md-10.col-lg-10.my-auto {
        position: inherit;
    }

    header {
        position: relative;
    }

    .navbar-collapse.collapse {
        height: 100vh;
    }

    .navbar-collapse.collapse nav {
        background: white;
        height: 100%;
        padding-top: 16px;
    }

    .navbar-collapse.collapse nav ul {
        display: block !important;
    }

    .navbar-collapse.collapse nav ul li {
        margin: 0;
    }

    .navbar-collapse.collapse nav ul li a {
        padding: 10px 24px;
        display: block;
        color: #fff;
        margin-left: 0;
        color: black;
    }  
    header .col-sm-10.col-md-10.col-lg-10.my-auto button.navbar-toggler:focus {
        outline: none;
        border: none;
        box-shadow: none;
    }
    header .col-sm-10.col-md-10.col-lg-10.my-auto .navbar-collapse.collapse.show + button.navbar-toggler span:before {transform: rotate(45deg);top: 15px;}

    header .col-sm-10.col-md-10.col-lg-10.my-auto .navbar-collapse.collapse.show + button.navbar-toggler span:after {
        top: 15px;
        transform: rotate(-45deg);
    }
    .footer-logos span {
        display: block;
        width: 100%;
        margin-right: 5px;
        max-width: 180px;
        margin-bottom: 10px;
    }
    footer .footer-block {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
    .training-slider i.slick-prev,
    .training-slider:hover button.slick-prev{
        left: 10px;
    }
    .training-slider i.slick-next,
    .training-slider:hover .slick-next{
        right: 10px;
    }
    .recept-inner {
        height: 260px;
    }
    .recept-infor h4 {
        margin: 0 0 5px 0;
        font-size: 18px;
        line-height: 22px;        
    }
    .recept-infor ul li{
        font-size: 14px;
    }
    .recept-inner-block a .recept-inner {
        height: 220px;
    }
    .tabbing-infobox .nav.nav-tabs .nav-link{
        font-size: 16px;
        margin: 0 10px;
    }
    .tabbing-infobox nav {
        margin-bottom: 10px;
    }
    .search-div {
        margin-bottom: 5px !important;
    }
    section.recepti-section.py-5 {
        padding: 20px 0 !important;
    }  
    section.recipes-detail {
        padding: 30px 0 !important;
    }

    .top-breakcrumb-box {
        margin-bottom: 25px;
    }

    a.back-link img {
        width: 13px;
    }

    .detail-content {
        padding-top: 20px;
    }

    .title-box h1 {
        font-size: 26px;
        line-height: 34px;
        margin: 0;
    }

    .title-box {
        margin-bottom: 15px !important;
    }

    .leftcontent-blk h3, .ritcont-list h3 {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 15px;
    }
}

@media(max-width:767px){
    .subscirpt-block label {
        width: 100%;
        padding: 10px 12px 10px 35px;
    }
    .subscirpt-block label:before{
        left:10px;
    }
    .subscirpt-block label:after{
        left:14px;
    }
    .left-subsi-blk h5{
        font-size: 16px;
        line-height: 22px;
    }
    .subscirpt-block .radioblock-div .right-subsi-blk h4 {
        font-size: 20px;
    }
    footer .footer-block{
        padding-top: 15px;
    }
    footer.py-5 {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
    }
    .titlewith-subtit h1 {
        font-size: 18px;
        margin-bottom: 18px;
    }
    .subscript-listing ul li {
        line-height: 17px;
        margin-bottom: 6px;
        font-size: 14px;
    }
    .login-block-box ul.nav li .nav-link{
        font-size: 16px;
        padding: 5px 10px 6px;
    }
    .btn.blank-btn,
    .btn.black-btn{
        font-size: 14px;
        height: 48px;
    }
    /* section.login-register-section {
        padding-top: 20px !important;
        padding-bottom: 20px !important;
        min-height: inherit;
    } */
    .login-block-box .tab-pane {
        padding: 20px 0 0;
    }
    .payment-textblk h3{
        font-size: 18px;
        line-height: 26px;
    }
    .train-inner{
        padding: 0px;
    }
    .training-slider {
        margin: 0 -12px;
    }
    .training-slider i.slick-next, .training-slider:hover .slick-next {
        right: 20px;
    }
    .training-slider i.slick-prev, .training-slider:hover button.slick-prev {
        left: 20px;
    }
    .traing-img {
        height: 200px;
    }
    .trainit-detail a h4,
    .trainit-detail a p {
        font-size: 16px;
        line-height: 22px;        
    }
    .training-slider i.slick-arrow, .recept-slider i.slick-arrow{
        top: 39%;
        transform: translateY(-39%);
    }
    .common-title h3{
        font-size: 22px;
        line-height: 30px;
    }
    .top-title h1 {
        font-size: 32px;
        line-height: 44px;        
    }
    .person-actdetail a span.link-arrows img {
        width: 10px;
    }
    .person-activity-blk {
        height: 230px;
    }
    .person-actdetail a {
        padding: 15px 35px 14px 15px;
    }
    .person-actdetail a span.link-arrows{
        right: 15px;
        top: 27px;
    }
    .recept-repeat-block{
        padding:0 5px;
    }
    .training-slider i.slick-prev, .recept-slider i.slick-prev {
        left: 25px;
    }
    
    .training-slider i.slick-next, .recept-slider i.slick-next {
        right: 25px;
    }
    .recepit-page-block .recept-repeat-block {
        width: 50%;
    }
    .c-container{
        padding: 15px;
    }
    section.login-register-section{
        padding: 0;
        min-height: auto;
    }
}

@media(max-width:550px){
    .podcast-block .leftpod-img {
        max-width: 100%;
    }
    .podcast-block .rigpod-cont {
        width: calc(100% - 0px);
        padding: 15px 40px 10px 0;
    }
    .top-title h1 {
        font-size: 26px;
        line-height: 36px;
    }
    .top-title {
        margin-bottom: 15px;
    }
    .podcast-block{
        padding: 15px;
    }
    .recepit-page-block .recept-repeat-block {
        width: 100%;
    }
    .recepit-page-block .recept-repeat-block .recept-inner-block{
        max-width: 100%;
        margin: 0 auto;
    }
    .tabbing-infobox .nav.nav-tabs .nav-link {
        font-size: 14px;
        margin: 0 7px;
    }
}

#finishwork-model {
    position: absolute;
    z-index: 999;
    overflow: inherit;
    padding: 40px 0 !important;
}

#finishwork-model .modal-dialog {
    transform: inherit;
    max-width: 100%;
    margin: 0;
}
body.modal-open {
    overflow: inherit !important;
    padding: 0 !important;
}



body.modal-open {
    overflow: inherit !important;
    padding: 0 !important;
}

.modal-backdrop.show {
    display: none;
}

#finishwork-model .modal-dialog .modal-content, #finishwork-model .modal-dialog {
    height: 100%;
    border: none;
    border-radius: 24px;
}
#finishwork-model .modal-dialog .modal-content .modal-header {
    border: none;
    padding: 0;
}

#finishwork-model .modal-dialog .modal-content .modal-header button.btn-close {
    margin: 0;
    position: absolute;
    left: 50px;
    top: 50px;
    font-size: 20px;
    opacity: 1;
    z-index: 99;
}

.outer-circlebox {
    max-width: 650px;
    height: 650px;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    border-radius: 100%;
    /* transform: scale(1.5); */
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: rgb(225,225,225); /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover,  rgba(225,225,225,.2) 0%, rgba(236,236,236,.2) 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(225,225,225,.2) 0%,rgba(236,236,236,.2) 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center,  rgba(225,225,225,.2) 0%,rgba(236,236,236,.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e1e1e1', endColorstr='#ececec',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

div#finishwork-model .modal-body {
    overflow: hidden;
    display: flex;
}

.middle-circle {
    max-width: 510px;
    height: 510px;
    margin: auto;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* transform: scale(1.3); */

    background: rgb(200,200,200); /* Old browsers */
    background: -moz-linear-gradient(-45deg,  rgba(200,200,200,.2) 0%, rgba(228,228,228,.2) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(200,200,200,.2) 0%,rgba(228,228,228,.2) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(200,200,200,.2) 0%,rgba(228,228,228,.2) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    width: 100%;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#c8c8c8', endColorstr='#e4e4e4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

.inner-circle {
    max-width: 350px; 
    height: 350px;
    width: 100%;
/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#d3d3d3+0,ebebeb+100 */
    background: rgb(211,211,211); /* Old browsers */
    background: -moz-linear-gradient(-45deg,  rgba(211,211,211,1) 0%, rgba(235,235,235,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg,  rgba(211,211,211,1) 0%,rgba(235,235,235,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg,  rgba(211,211,211,1) 0%,rgba(235,235,235,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d3d3d3', endColorstr='#ebebeb',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
    border-radius: 100%;
    /* transform: scale(1); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.inner-circle p {
    font-size: 15px;
    line-height: 30px;
    transform: scale(0.6);
    line-height: 21px;
    margin: 0;
}

.inner-circle span {
    font-size: 16px;
}

.modal-dialog {
    height: 90vh;
}

@media(max-width:767px){
    .outer-circlebox{
        transform: scale(1.0);
        width: 320px;
        max-width: 100%;
        height: 320px;
    }
    .middle-circle {
        transform: scale(1.0);
        width: 290px;
        max-width: 100%;
        height: 290px;
    }
    .inner-circle {
        transform: scale(1.0);
        width: 260px;
        max-width: 100%;
        height: 260px;
    }
    .inner-circle p{
        transform: scale(1.0);
        font-size: 14px;
        padding: 15px;
    }
    #finishwork-model .modal-dialog .modal-content .modal-header button.btn-close{
        top:20px;
        left:20px;
    }
    
}

header nav ul li.active-page a {
    font-weight: 700;
}

.back-page-arrow{
    cursor: default;
}
.pointer{
    cursor: pointer;
}

.pointer * {
    cursor: pointer;
}

.pointer:hover{
    cursor: default;
}
.hand-point:hover{
    cursor: pointer;
}
.owl-theme .owl-nav [class*=owl-]{
    background-color: transparent !important;
}


.btn-light.bg-primary.text-white {
    background-color: #000000 !important;
}
.btn-link{
    color: #000000 !important;
}
.ngb-dp-weekday{
    color: #000000 !important;
}

.inner-training-box .img-box img {
    width: 100%;
}


@media (max-width: 550px) {
    .login-register-section .c-container{
        padding: 0 24px;
    }

    .login-register-section .login-block-box {
        padding: 15px 16px;
    }

    .login-block-box .tab-pane .form-floating .form-control {
        /* height: 54px; */
        /* padding-top: 24px; */
    }

    .titlewith-subtit h1 {
        font-size: 20px !important;
    }

    .radioblock-div .left-subsi-blk h5 {
        font-weight: bold !important;
    }

    .radioblock-div {
        /* gap: 16px; */
    }

    .radioblock-div .left-subsi-blk {
        flex: 1;
    }

    .radioblock-div .right-subsi-blk {
        flex-basis: 85px !important;
    }

    .radioblock-div .right-subsi-blk h4 {
        font-size: 20px !important;
    }

    .radioblock-div .right-subsi-blk p {
        font-size: 12px;
    }

    .navbar-toggler {
        right: 24px !important;
    }
}